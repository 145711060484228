import React, { memo, useRef, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import rehypeHighlight from "rehype-highlight";
import User from "../assets/images/user.svg";
import SmLogo from "../assets/images/sm-logo.svg";

const OneMessage = memo(({ chat, user, isLatest, loading }) => {
  const messageRef = useRef(null);

  useEffect(() => {
    const chatArea = document.getElementById("chat-messages");
      if (chatArea && messageRef.current) {
        const isAtBottom =
          chatArea.scrollHeight - chatArea.scrollTop === chatArea.clientHeight;
        if (isAtBottom) {
          messageRef.current.style.marginBottom = "10px";
        } else {
          messageRef.current.style.marginBottom = "0";
        }
      }
    }, [chat]);

    const trimContent = (content, maxWords = 500) => {
      const words = content.split(/\s+/); 
      if (words.length > maxWords) {
        return words.slice(0, maxWords).join(" ") + " ..."; 
      }
      return content;
    };

  return (
    <React.Fragment>
      <div className="mt-3" ref={messageRef}>
        {chat.role === "user" ? (
          <>
            <div
              className="rounded-circle bg-round2 img-center"
              style={{ height: "32px", width: "32px" }}
            >
              <img
                src={user.picture ? user.picture : User}
                alt="User Icon"
                className="rounded-circle"
                style={{ width: "35px", objectFit: "cover" }}
              />
            </div>
            <div className="message mt-3 rounded-15 fs-15 msg-send txt-light md-black">
              <ReactMarkdown
                className="margin-0 fs-15 rounded-15 md-black txt-light"
                rehypePlugins={[rehypeHighlight]}
              >
                {trimContent(chat.content)}
              </ReactMarkdown>
            </div>
            <div
              className="rounded-lg rounded-8 mt-4 bg-round2 img-center d-flex justify-content-center align-items-center"
              style={{ height: "32px", width: "32px", position: "relative" }}
            >
              {isLatest && loading ? (
                <div
                  className="spinner-border"
                  style={{
                    width: "16px",
                    height: "16px",
                    borderWidth: "2px",
                    color: "#61FCD9",
                  }}
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                <img
                  src={SmLogo}
                  alt="Bot Icon"
                  style={{ width: "18px", objectFit: "cover" }}
                />
              )}
            </div>
          </>
        ) : (
          <>
            <div className="message mt-4 rounded-15 txt-light md-black fs-15">
              <ReactMarkdown
                className="margin-0 fs-15 rounded-15 md-black txt-light p-3"
                rehypePlugins={[rehypeHighlight]}
              >
                {chat.content}
              </ReactMarkdown>
            </div>
          </>
        )}
      </div>

      {chat.role !== "user" && <hr className="hr-color" />}
    </React.Fragment>
  );
});

const ChatMessages = ({ messages, user, loading }) => {
  return (
    <div id="chat-messages" style={{ overflowY: "visible" }}>
      {messages.map((chat, index) => (
        <OneMessage
          key={index}
          chat={chat}
          user={user}
          loading={loading}
          isLatest={index === messages.length - 2 || index === messages.length - 1}
        />
      ))}
    </div>
  );
};

export default ChatMessages;
