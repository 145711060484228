import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/loader.css'; 
import { collection, query, where, limit, doc, setDoc, getDocs, getDoc, updateDoc, serverTimestamp } from "firebase/firestore";
import { db } from '../firebaseUtils/firebaseConfig';  
import axios from 'axios';

const PhotonAI = () => {
  const domain = process.env.REACT_APP_DOMAIN
  const api_url = process.env.REACT_APP_API_URL

  const invokeCloudRun = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_API_URL}/invoke`);
    } catch (err) {
    }
  };

  async function getUserDetails(access_token,refresh_token){
    const userInfoEndpoint = 'https://www.googleapis.com/oauth2/v3/userinfo';
    try {
        const response = await fetch(userInfoEndpoint, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        });
        if (!response.ok) {
            return
        }
        const userInfo = await response.json();
        return userInfo
    } catch (error) {
        console.error('Error fetching user info:', error);
    }
  }

  const hashEmail = async (email) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(email);
  
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');
  
    return hashHex;
  };

  async function refreshTokenFunc(refresh_token) {
    const refreshEndpoint = "https://oauth2.googleapis.com/token";
    let refreshToken = refresh_token

    if (!refreshToken) {
      console.error("No refresh token available");
      return null;
    }

    try {
      const response = await fetch(refreshEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          client_id: process.env.REACT_APP_CLIENT_ID,
          client_secret: process.env.REACT_APP_CLIENT_SECRET,
          refresh_token: refreshToken,
          grant_type: "refresh_token",
        }),
      });

      if (!response.ok) {
        console.error("Failed to refresh token");
        return null;
      }

      const data = await response.json();
      const newAccessToken = data.access_token;
      return newAccessToken;
    } catch (error) {
      console.error("Error refreshing token:", error);
      return null;
    }
  }

  async function call_set_user_api(access_token, refresh_token , i=0) {
    try {
        const data = await getUserDetails(access_token, refresh_token);
        const email = data.email
        const user_id = await hashEmail(email);
        const org_id = process.env.REACT_APP_ORG_ID;

        const usersRef = collection(db, "organisation", org_id, "users");
        const q = query(usersRef, where("user_id", "==", user_id), limit(1));

        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
                const userDoc = querySnapshot.docs[0];
                const userData = userDoc.data();
    
                if (userData.access_token === access_token) {
                    localStorage.setItem("access_token", access_token);
                    localStorage.setItem("refresh_token", refresh_token);
                    localStorage.setItem("email", email);
                    let url = domain + "/chat"
                    window.location.href = url;
                } else if (email === userData.email) {
                    await updateDoc(userDoc.ref, {
                        access_token: access_token,
                    });
                    localStorage.setItem("access_token", access_token);
                    localStorage.setItem("refresh_token", refresh_token);
                    localStorage.setItem("email", email);
                    let url = domain + "/chat"
                    window.location.href = url;
                } else {
                    let url = domain + "/login"
                    window.location.href = url;
                    return { email: "" };
                }
            } else {
                const userDocRef = doc(usersRef, user_id.toString()); // Create a DocumentReference
                const userData = await getUserDetails(access_token, refresh_token); // Retrieve user details

                const name = userData.name || "";
                const picture = userData.picture || "";

                await setDoc(userDocRef, {
                    user_id: user_id,
                    email: email,
                    name: name,
                    picture: picture,
                    access_token: access_token,
                    refresh_token: refresh_token,
                    org_mode: false,
                    create_new_chat: true,
                    created_at: serverTimestamp(),
                    updated_at: serverTimestamp(),
                });

                localStorage.setItem("access_token", access_token);
                localStorage.setItem("refresh_token", refresh_token);
                localStorage.setItem("email", email);
                let url = domain + "/chat"
                window.location.href = url;
        }
    } catch (error) { 
        const new_token = await refreshTokenFunc(refresh_token)
        if (i <= 5){
            call_set_user_api(new_token,refresh_token,i+1)
        }else{
            let url = domain + "/login?message=Problem with login"
            window.location.href = url;
        }
    }
}

    useEffect(() => { 
        async function google_auth(page) {
            var access_token = localStorage.getItem('access_token');
            var refresh_token = localStorage.getItem('refresh_token');
            var email = localStorage.getItem('email');
            if(access_token && refresh_token && email) {
                await call_set_user_api(access_token, refresh_token)
            }else{
                if(page === "home"){
                    window.location.href = domain + "/login"
                } else {
                    const elem = document.getElementById('process');
                    if(elem){
                        elem.classList.add('d-none');
                    }
                }
            }
        }
        invokeCloudRun()
        google_auth("home");
    }, []);

    return (
        <div style={{ height: '100vh' }} className="d-flex align-items-center justify-content-center bg-dark-app">
            <span className="loader"></span>
        </div>
    );
};

export default PhotonAI;
