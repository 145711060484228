import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/loader.css';
import '../assets/css/chat-style.css';
import Applogo from "../assets/images/app-logo.svg"
import Googlelogo from "../assets/images/google-logo.svg"


const PhotonAI = () => {

  let YOUR_REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI;
  let YOUR_CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

  useEffect(() => {
    // Display message alert if query param 'message' is present
    const urlParams = new URLSearchParams(window.location.search);
    const message = urlParams.get('message');
    if (message) {
      const messageDiv = document.getElementById('messageAlert');
      messageDiv.textContent = decodeURIComponent(message);
      messageDiv.style.display = 'block';

      setTimeout(() => {
        messageDiv.style.display = 'none';
      }, 2000);
    }
  }, []);

  async function oauth_sign_in() {
    let oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';
    let form = document.createElement('form');
    form.setAttribute('method', 'GET');
    form.setAttribute('action', oauth2Endpoint);

    let params = {'client_id': YOUR_CLIENT_ID,
                  'redirect_uri': YOUR_REDIRECT_URI,
                  'scope': 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid',
                  'state': 'try_sample_request',
                  'include_granted_scopes': 'true',
                  'response_type': 'code',
                'access_type': 'offline',
            'prompt': 'consent'
    };

    for (let p in params) {
      let input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', p);
      input.setAttribute('value', params[p]);
      form.appendChild(input);
    }

    document.body.appendChild(form);
    form.submit();
  }


  return (
    <div id="login-main-new" style={{ height: '100vh' }}>
      {/* Message Alert */}
      <div
        className="alert alert-primary w-25 mt-3 mcl-2 font-sm fade-out"
        style={{ position: 'fixed', display: 'none' }}
        role="alert"
        id="messageAlert"
      ></div>

      {/* Login Content */}
      <div className="d-flex justify-content-center align-items-center black" style={{ height: '100vh' }}>
        <div className="d-flex" style={{ position: 'absolute', top: '170px' }}>
          <img className="mcr-1" height="60px" src={Applogo} alt="App Logo" />
        </div>
        <div
          className="card shadow-sm cpp md-black d-flex justify-content-center align-items-center"
          onClick={oauth_sign_in}
          style={{
            borderRadius: '10px',
            borderBottom: '3px solid #61FCD9',
            width: '100%',
            maxWidth: '320px',
            minWidth: '320px',
            height: '120px',
          }}
        >
          <div
            className="d-flex white align-items-center cpp"
            style={{
              paddingLeft: '12px',
              borderRadius: '20px',
              width: '174px',
              height: '40px',
              cursor: 'pointer',
            }}
          >
            <img className="mcr-1" height="20px" src={Googlelogo} alt="Google Logo" />
            <span style={{ color: 'black', fontSize: '13px' }}>Sign in with Google</span>
          </div>
        </div>
        <div className="d-flex" style={{ position: 'absolute', bottom: '20px' }}>
          <span className="font-xsm gray-txt">
            PhotonAI © 2024. Powered with <span className="font-xsm green-txt">Synapse Xtreme Engine (SXE)</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PhotonAI;
