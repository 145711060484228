import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { collection, query, where, limit, doc, setDoc, getDocs, getDoc, updateDoc, serverTimestamp } from "firebase/firestore";
import '../assets/css/loader.css';
import { db } from '../firebaseUtils/firebaseConfig';  

const PhotonAI = () => {
    let YOUR_REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI;
    let YOUR_CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
    let YOUR_CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;
    const domain = process.env.REACT_APP_DOMAIN
    const api_url = process.env.REACT_APP_API_URL
    // var YOUR_REDIRECT_URI = 'http://localhost:8080/setUser';

  async function getUserDetails(access_token,refresh_token){
    const userInfoEndpoint = 'https://www.googleapis.com/oauth2/v3/userinfo';
    try {
        const response = await fetch(userInfoEndpoint, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        });
        if (!response.ok) {
            return
        }
        const userInfo = await response.json();
        return userInfo
    } catch (error) {
        console.error('Error fetching user info:', error);
    }
  }

  const hashEmail = async (email) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(email);
  
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');
  
    return hashHex;
  };

  const getDomain = async (email) => {
    const domainRef = doc(db, "organisation", process.env.REACT_APP_ORG_ID);
    const querySnapshot = await getDoc(domainRef);
    const org_data = querySnapshot.data();
    const email_trim = email.split("@")[1];
    if (org_data.domain !== email_trim) {
        const errorMessage = `email domain ${org_data.domain} are allowed`;
        const url = `${domain}/login?message=${encodeURIComponent(errorMessage)}`;
        window.location.href = url;
        return false
        }
    return true
    };

  async function call_set_user_api(access_token, refresh_token) {
        try {
            const data = await getUserDetails(access_token, refresh_token);
            const email = data.email

            const valid = await getDomain(email)
            
            if(!valid){
                return 
            }

            const user_id = await hashEmail(email);
            const org_id = process.env.REACT_APP_ORG_ID;

            const usersRef = collection(db, "organisation", org_id, "users");
            const q = query(usersRef, where("user_id", "==", user_id), limit(1));
    
            const querySnapshot = await getDocs(q);
    
            if (!querySnapshot.empty) {
                const userDoc = querySnapshot.docs[0];
                const userData = userDoc.data();
    
                if (userData.access_token === access_token) {
                    localStorage.setItem("access_token", access_token);
                    localStorage.setItem("refresh_token", refresh_token);
                    localStorage.setItem("email", email);
                    let url = domain + "/chat"
                    window.location.href = url;
                } else if (email === userData.email) {
                    await updateDoc(userDoc.ref, {
                        access_token: access_token,
                    });
                    localStorage.setItem("access_token", access_token);
                    localStorage.setItem("refresh_token", refresh_token);
                    localStorage.setItem("email", email);
                    let url = domain + "/chat"
                    window.location.href = url;
                } else {
                    console.log("3")
                    let url = domain + "/login"
                    window.location.href = url;
                    return { email: "" };
                }
            } else {
                const userDocRef = doc(usersRef, user_id.toString()); 
                const userData = await getUserDetails(access_token, refresh_token); 
    
                const name = userData.name || "";
                const picture = userData.picture || "";
    
                await setDoc(userDocRef, {
                    user_id: user_id,
                    email: email,
                    name: name,
                    picture: picture,
                    access_token: access_token,
                    refresh_token: refresh_token,
                    org_mode: false,
                    create_new_chat: true,
                    created_at: serverTimestamp(),
                    updated_at: serverTimestamp(),
                });
    
                localStorage.setItem("access_token", access_token);
                localStorage.setItem("refresh_token", refresh_token);
                localStorage.setItem("email", email);

                let url = domain + "/chat"
                window.location.href = url;
            }
        } catch (error) {
            console.error("Error in user auth:", error);
            let url = domain + "/login"
            window.location.href = url;
            return { email: "" };
        }
    }

  async function exchangeCodeForTokens(code) {
        let client_id = YOUR_CLIENT_ID
        let client_secret = YOUR_CLIENT_SECRET
        let redirect_uri = YOUR_REDIRECT_URI;

        let tokenEndpoint = 'https://oauth2.googleapis.com/token';

        let params = new URLSearchParams();
        params.append('code', code);
        params.append('client_id', client_id);
        params.append('client_secret', client_secret);
        params.append('redirect_uri', redirect_uri);
        params.append('grant_type', 'authorization_code');

        let api_data = {};
        try {
            const response = await fetch(tokenEndpoint, {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: params.toString()
            });
            const data = await response.json();

            if (data.access_token) {
                api_data = data;
                return api_data;
            } else {
                return null
            }
        } catch (error) {
            console.error('Error fetching token:', error);
        return null
        }
    }

    function getURLParameter(name) {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        return params.get(name);
      }
    

    useEffect(() => {
        async function sendData() {
            const code = getURLParameter('code')
            const data = await exchangeCodeForTokens(code)
            if (data){
                call_set_user_api(data.access_token,data.refresh_token)
            }
          }
        sendData();
    }, []);

    return (
        <div style={{ height: '100vh' }} className="d-flex align-items-center justify-content-center bg-dark-app">
            <span className="loader"></span>
        </div>
    );
};

export default PhotonAI;
